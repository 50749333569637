import { template as template_6ca71f51cc274037990fb3bc186ae714 } from "@ember/template-compiler";
const FKLabel = template_6ca71f51cc274037990fb3bc186ae714(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
