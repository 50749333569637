import { template as template_c889551200f74fdb94d273b0bb4c31ad } from "@ember/template-compiler";
const SidebarSectionMessage = template_c889551200f74fdb94d273b0bb4c31ad(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
