import { template as template_262dba957746460eb86c4ce1d260e6fb } from "@ember/template-compiler";
const FKControlMenuContainer = template_262dba957746460eb86c4ce1d260e6fb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
